<template>
  <div :class="{ node: true, 'node-error-state': showError }">
    <div :class="{ 'node-body': true, error: showError }">
      <div class="node-body-main" @click="$emit('selected')">
        <div class="node-body-main-header">
          <ellipsis class="title" hover-tip :content="config.name !== '默认条件' ? '条件' + level : '默认条件'" />
          <span class="level">优先级{{ level }}</span>
          <span class="option" v-if="diagramMode !== 'viewer'">
            <i class="iconfont icon-cha1 el-icon-close" @click.stop="$emit('delNode')" v-if="!allDisabled"></i>
          </span>
        </div>
        <i
          class="iconfont icon-cha1 el-close"
          v-if="config.name !== '默认条件' && !allDisabled"
          @click.stop="$emit('delNode')"
        ></i>
        <div class="node-body-main-content">
          <div class="placeholder">
            <span v-if="level == size && size != 0 && showContent">其他情况走此分支</span>
            <span v-else-if="showContent">{{ placeholder }}</span>
            <p class="node-content-item" v-for="el in subConditions">
              {{ el }}
            </p>
          </div>
          <i class="iconfont icon-right"></i>
        </div>
      </div>
      <div class="node-body-right" @click="$emit('rightMove')" v-if="level < size && diagramMode !== 'viewer'">
        <i><RightOutlined /></i>
      </div>
      <div class="no-err" v-if="showError">
        <img src="@/assets/images/business/tip.png" alt="" />
      </div>
    </div>
    <div class="node-footer">
      <div class="btn">
        <insert-button v-if="diagramMode !== 'viewer'" @insertNode="type => insertNode(type)"> </insert-button>
      </div>
    </div>
  </div>
</template>

<script>
import InsertButton from '../../InsertButton.vue'
import { ValueType } from '../../form/ComponentsConfigExport'
import { CopyOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons-vue'

const groupNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
export default {
  name: 'ConditionNode',
  components: { InsertButton, CopyOutlined, RightOutlined, LeftOutlined },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    //索引位置
    level: {
      type: Number,
      default: 1
    },
    //条件数
    size: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      ValueType,
      groupNames,
      placeholder: '请设置条件',
      errorInfo: '',
      showError: false,
      showContent: true
    }
  },
  created() {},
  computed: {
    diagramMode() {
      return this.$store.state.process.diagramMode
    },
    subConditions() {
      // console.log(this.config)
      let confitions = []
      const groups = this.config.props.groups[0].conditions
      // console.log(88,groups);
      if (groups.length === 0 || (groups.length === 1 && !groups[0].value.length)) {
        this.showContent = true
        confitions = []
      } else {
        this.showError = false
        groups.forEach((item, index) => {
          console.log('条件', item)
          // if (item.value.length > 0) {
          let branOne = ''
          let branTwo = ''
          // 引号前
          branOne = item.title
          if (item.title === '发起人') {
            const texts = []
            item.value.forEach(el => texts.push(el.dataName))
            branTwo = String(texts).replaceAll(',', '、')
          } else {
            // 普通符号+数值
            let bran = []
            if (item.compare === 'B') {
              bran = ['>', '<']
            } else if (item.compare === 'AB') {
              bran = ['≧', '<']
            } else if (item.compare === 'BA') {
              bran = ['>', '≤']
            } else if (item.compare === 'ABA') {
              bran = ['≧', '≤']
            } else {
              bran.push(item.compare)
            }
            // if(['B', 'AB', 'BA', 'ABA'].includes(item.compare)) {
            //   bran.push(item.betDetermine)
            //   bran.push(item.branDetermine)
            // } else {
            //   bran.push(item.compare)
            // }
            let nums = []
            item.value.forEach((el, ind) => {
              el && nums.push(`${bran[ind]}${el}`)
            })
            branTwo = String(nums).replaceAll(',', '，')
          }
          confitions.push(`${branOne}: ${branTwo}`)
          this.showContent = false
          // }
        })
      }
      return confitions
    },
    formItems() {
      return this.$store.state.process.design.formItems
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  methods: {
    //校验数据配置的合法性
    validate(err) {
      const groups = this.config.props.groups[0].conditions
      // console.log(this.formItems)
      // 没有添加条件
      console.log('111111', this.config)
      const branName = this.config.name === '默认条件' ? '默认条件' : `条件${this.level}`
      // if(!this.config.children.id) {
      //   err.push(`每条分支上至少设置一个审批节点`)
      // }
      if (this.config.name === '默认条件') return
      if (groups.length === 0) {
        this.showError = true
        err.push(`请完善${branName}`)
      } else {
        groups &&
          groups.forEach((item, index) => {
            console.log('条件', item)
            const repeact = this.formItems?.find(el => el.id === item.id)
            // console.log(7777777777,repeact);
            if (repeact && !repeact.props.required) {
              err.push(`${repeact.title}组件已不是必填项，请重新选择条件`)
              this.showError = true
            } else if (item.value.length === 0) {
              // 条件为发起人时成员不能为空
              err.push(`请完善${branName}`)
              this.showError = true
            } else if (
              item.compare === 'B' ||
              item.compare === 'AB' ||
              item.compare === 'BA' ||
              item.compare === 'ABA'
            ) {
              let isNo = false
              item.value.forEach(el => {
                if (el === '') {
                  isNo = true
                }
              })
              if (item.value.length !== 2 || isNo) {
                err.push(`请完善${branName}`)
                this.showError = true
              }
            } else {
              this.showError = false
            }
          })
      }
      // if(formItems)
      return err
    },
    insertNode(type) {
      // console.log(1111, type);
      this.$emit('insertNode', type)
    }
  }
}
</script>

<style lang="less" scoped>
.node-error-state {
  .node-body {
    box-shadow: 0px 0px 10px 0px rgba(214, 0, 0, 0.4) !important;
  }
}

.node {
  padding: 30px 55px 0;
  position: relative;
  .el-close {
    display: none;
  }
  &:hover {
    .el-close {
      color: #8c8c8c;
      position: absolute;
      right: -14px;
      top: -18px;
      display: inline;
    }
  }
  .node-body {
    cursor: pointer;
    min-height: 80px;
    position: relative;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #d8d8d8;
    .no-err {
      position: absolute;
      right: -32px;
      bottom: 40%;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .node-body-left,
    .node-body-right {
      display: flex;
      align-items: center;
      position: absolute;
      height: 100%;
      i {
        display: none;
      }

      &:hover {
        background-color: #ececec;
      }
    }

    .node-body-main {
      //position: absolute;
      // width: 188px;
      width: 220px;
      padding: 8px 6px;
      // height: 100px;
      // margin-right: 12px;
      display: inline-block;

      .node-body-main-header {
        width: 100%;
        // padding: 8px 0px 0px;
        font-size: xx-small;
        // position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px 8px;

        .title {
          color: #333333;
          display: inline-block;
          line-height: 22px;
          width: 100px;
          font-size: 16px;
          // margin-left: 12px;
        }

        .level {
          // position: absolute;
          // right: 4px;
          color: #c3161c;
          font-size: 14px;
        }

        .option {
          position: absolute;
          right: 0;
          display: none;
          font-size: medium;

          i {
            color: #888888;
            padding: 0 3px;
          }
        }
      }

      .node-body-main-content {
        padding: 6px;
        // margin: 0px 6px 6px;
        color: #656363;
        font-size: 14px;
        background: #fbfbfb;
        width: 100%;
        // height: 56px;
        border-radius: 6px;
        padding: 12px;
        line-height: 22px;
        i {
          position: absolute;
          top: 55%;
          right: 16px;
          font-size: medium;
          color: rgba(0, 0, 0, 0.25);
        }

        .placeholder {
          color: #8c8c8c;
        }
        .node-content-item {
          width: 85%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .node-footer {
    position: relative;
    .btn {
      width: 100%;
      display: flex;
      height: 70px;
      padding: 20px 0 32px;
      justify-content: center;
    }
    /deep/ .el-button {
      height: 32px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      width: 1px;
      height: 100%;
      background-color: #e1e1e1;
    }
  }
}
</style>
